jQuery(document).foundation();

$(document).ready(function() {
	
	
	// ANIMATIONS + + + + + + + + + + + +
	
	// Header
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();

		if (scroll >= 150) {
      $(".nav-top-wrapper").removeClass("add-padding");	
			$(".nav-top-wrapper").addClass("remove-padding");
			$(".nav-top-wrapper").addClass("shade");
			$(".mobile-icon-bar").addClass("shade");
		}
		if (scroll < 40) {
      $(".nav-top-wrapper").removeClass("remove-padding");
      $(".nav-top-wrapper").addClass("add-padding");
			$(".nav-top-wrapper").removeClass("shade");
			$(".mobile-icon-bar").removeClass("shade");
		}
	});	
	
	
	// HELPER + + + + + + + + + + + +
	
	// Loading CGM iFrame only at click
	$("a.only-on-click").on('click touchstart', function(){
    document.getElementById("eServiceIFrame").src = getUrl();
	});
	
	
	// https://foundation.zurb.com/sites/docs/drilldown-menu.html#js-events
	
	// Hide "Service Links" on sublevels of Drilldown Menu
	$('[data-drilldown]').on('open.zf.drilldown', function() {
	  $('.nav-service_links_mobile').hide();
	});
	
	// Show "Service Links" on 1 level of Drilldown Menu
	$('[data-drilldown]').on('hide.zf.drilldown', function() {
	  $('.nav-service_links_mobile').show();
	});
	
	
	
});


